<template>
  <div class="header-wrap">
    <div class="d-flex">
      <el-icon style="font-size: 2rem; margin-right: 8px; cursor: pointer" @click="TOGGLE_SIDEBAR()">
        <component :is="getSideBarCollapse ? 'Expand' : 'Fold'"></component>
      </el-icon>
      <el-breadcrumb separator-icon="ArrowRight" style="font-size: 1.7rem; font-weight: 500">
        <el-breadcrumb-item v-for="(item, index) in getBreadcrumb()" :key="index">
          {{ item.meta.name }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-dropdown placement="bottom-end" @command="handleCommand">
      <span class="el-dropdown-link">
        {{ shop }}－{{ user_name }}
        <el-icon class="el-icon--right">
          <arrow-down />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="log_out">登出</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>
<script>
import { getCurrentInstance, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
export default {
  name: 'v-header',
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const getBreadcrumb = () => {
      return route.matched.filter((e) => e.meta.name);
    };
    const shop = sessionStorage.getItem('shop');
    const user_name = sessionStorage.getItem('user_name');
    //menu折疊
    const getSideBarCollapse = useGetters('user', ['getSideBarCollapse']);
    const { TOGGLE_SIDEBAR, SET_COLLAPSE } = useMutations('user', ['TOGGLE_SIDEBAR', 'SET_COLLAPSE']);
    //偵測螢幕大小改變Collapse
    const changeSidebar = () => {
      if (window.innerWidth >= 1200) SET_COLLAPSE(false);
      else SET_COLLAPSE(true);
    };
    onBeforeMount(() => {
      window.addEventListener('resize', changeSidebar);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', changeSidebar);
    });
    const handleCommand = (action) => {
      switch (action) {
        case 'log_out':
          handleLogout();
          break;
      }
    };
    //登出
    const doLogout = useActions('user', ['doLogout']);
    const getDefaultIsChange = useGetters('default', ['getDefaultIsChange']);
    const getConfigIsChange = useGetters('shopSystem', ['getConfigIsChange']);
    const handleLogout = () => {
      if (getDefaultIsChange.value || getConfigIsChange.value) {
        proxy.$alert(`當前頁面尚未儲存，請儲存後再登出。`, '尚未儲存', {
          type: 'error',
          confirmButtonText: '確認',
          confirmButtonClass: 'el-button--danger',
        });
      } else {
        proxy
          .$confirm(`確定要登出嗎？`, '確認', {
            confirmButtonText: '登出',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
          })
          .then(() => {
            doLogout().catch((e) => e);
          })
          .catch((e) => e);
      }
    };
    return {
      getBreadcrumb,
      shop,
      user_name,
      getSideBarCollapse,
      TOGGLE_SIDEBAR,
      handleCommand,
    };
  },
};
</script>
<style lang="scss" scoped>
.header-wrap {
  height: $header-height;
  @include flex-between-center;
}
.el-dropdown-link {
  font-size: 1.6rem;
  color: $color-text;
  cursor: pointer;
}
:deep(.el-breadcrumb__inner) {
  color: $color-text !important;
}
</style>
